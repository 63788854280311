// import {
//   configureStore,
//   ThunkAction,
//   Action,
//   combineReducers,
// } from "@reduxjs/toolkit";
// import {thunk} from "redux-thunk";
// import logger from "redux-logger";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import authSlice from "../containers/Authentication/authSlice";
// import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

// const rootReducer = combineReducers({
//   user: authSlice
// });

// const persistConfig = {
//   key: "root",
//   storage,
//   stateReconciler: autoMergeLevel2,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;

// export const store = configureStore({
//   reducer: persistedReducer,
//   devTools: true,
//   /** @ts-ignore */
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(thunk, logger),
// });

// export const persistor = persistStore(store);

import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import logger from "redux-logger";
import { thunk } from "redux-thunk";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "../containers/Authentication/authSlice";
import bankSlice from "../reducers/bankSlice";
import creatorspageSlice from "../containers/MyPage/creatorspageSlice";
import membershipSlice from "../containers/Memberships/membershipSlice";
import supportersSlice from "../containers/Supporters/supportersSlice";
import overviewSlice from "../containers/Overview/overviewSlice";

const rootReducer = combineReducers({
  user: authSlice,
  banks: bankSlice,
  creatorsPage: creatorspageSlice,
  membership: membershipSlice,
  supportersPage: supportersSlice,
  overviewsPage: overviewSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk, logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
