import { CloudinaryImage } from "@cloudinary/url-gen";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { toast } from "sonner";
import { IReward } from "../types";

const AssetTransform = (imgName: string) => {
  return new CloudinaryImage(imgName, { cloudName: "groomlyhq" }).delivery(
    quality(60)
  );
};

export default AssetTransform;

export const getCurrency = (currency: string) => {
  switch (currency) {
    case "USD":
      return "$";
    case "NGN":
      return "₦";
    case "KES":
      return "KSh";
    case "ZMW":
      return "ZK";
    case "GHS":
      return "₵";
    case "EGP":
      return "£";
    case "ZAR":
      return "R";
    default:
      // Default case for unknown currencies
      return currency;
  }
};

export const getCountries = (prop: string) => {
  switch (prop) {
    case "NGN":
      return "Nigeria";
    case "KES":
      return "Kenya";
    case "ZM":
      return "Zambia";
    case "GHS":
      return "Ghana";
    case "EGP":
      return "Egypt";
    case "ZAR":
      return "South Africa";
    default:
      return prop;
  }
};

export const getSimplifiedError = (error: any) => {
  console.log(error.response);
  if (!error.response) {
    toast.error(
      "Something went wrong, check your internet and please try again"
    );
    return "Something went wrong, check your internet and please try again";
  }
  if (error.response?.status === 500) {
    toast.error("Sorry an unexpected error occurred.");
    return "Sorry an unexpected error occurred.";
  }
  if (error.response?.status === 503) {
    if (
      error.response?.data?.message ===
      "Error occurred while communicating with email service."
    ) {
      toast.error(
        "We couldn't connect to our email server, if it persist, please use our help desk at the bottom right we response in 5 minutes"
      );
      return "Sorry an unexpected error occurred.";
    } else {
      toast.error(
        "Sorry an unexpected error occurred, if it persist, please use our help desk at the bottom right we response in 5 minutes"
      );
      return "Sorry an unexpected error occurred.";
    }
  }

  if (error.response.status === 404) {
    if (
      error.response.data.error === "user not found" ||
      error.response?.data?.message === "user not found"
    ) {
      toast.error(error.response.data.message);
      setTimeout(() => {
        window.location.replace("/register");
      }, 1000);
    }

    if (error.response?.data?.message === "Record not found") {
      return toast.error("You need to edit your public page and publish it");
    }
    return error.response.data.error;
  } else if (error.response.status === 400) {
    toast.error(error.response.data.message);
    return "Token has expired, please log in";
  } else if (error.response.status === 401) {
    if (error.response.data.message === "Invalid credentials") {
      toast.error("Your email or password is incorrect");
    }
    toast.error(error.response.data.message || error.response.data.error);
    return error.response.data.message;
  } else {
    toast.error(error.response.data.error);
    return error.response.data.error;
  }
};

export const formatAsCurrency = (value: string): string => {
  const formattedValue = `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  return formattedValue;
};

export const obfuscateEmail = (email: string): string => {
  if (email === "" || email === null) return "";
  const [username, domain] = email.split("@");
  const obfuscatedUsername = `${username[0]}***${
    username[username.length - 1]
  }`;
  const obfuscatedDomainPart =
    domain.substring(0, 1) + "..." + domain.substring(domain.indexOf(".") + 1);
  return `${obfuscatedUsername}@${obfuscatedDomainPart}`;
};

export const generateRandomString = () => {
  const characters =
    "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const length = 8;
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
};

export const rewards: IReward[] = [
  {
    id: "rwd_iaodfa9",
    iconType: "someIcon",
    title: "General Support",
    slug: "general-support",
    description:
      "Add this benefit to indicate that some or all of this tier is purely monetary support (i.e. members don’t receive anything in return) to ensure members pay the correct amount of sales tax for their pledge where applicable.",
  },
  {
    id: "rwd_s98fa2s",
    iconType: "someIcon",
    title: "Unlock exclusive posts and messages",
    slug: "unlock-exclusive-posts-and-messages",
    description: "Offer bonus content and messages to your members.",
  },
  {
    id: "rwd_3hj4k51",
    iconType: "someIcon",
    title: "Shout out for new members",
    slug: "shout-out-for-new-members",
    description: "Give your members a shout out when they join.",
  },
  {
    id: "rwd_1f35kjd",
    iconType: "someIcon",
    title: "Access to full library",
    slug: "access-to-full-library",
    description: "Give access to your complete backlog of posts.",
  },
  {
    id: "rwd_5gk3mf8",
    iconType: "someIcon",
    title: "Access to Digital downloads",
    slug: "access-to-digital-downloads",
    description:
      "Offer your members total access to download your digital goods (ebooks, courses etc).",
  },
  {
    id: "rwd_d93jfa7",
    iconType: "someIcon",
    title: "Free & Discounts offers digital downloads",
    slug: "free-and-discounts-offers-digital-downloads",
    description:
      "Offer exciting discounts on your course/1:1 consultation prices, etc.",
  },
  {
    id: "rwd_48jf9s2",
    iconType: "someIcon",
    title: "Work in progress updates",
    slug: "work-in-progress-updates",
    description: "Provide rough drafts and clips of your latest projects.",
  },
  {
    id: "rwd_g6h24kj",
    iconType: "someIcon",
    title: "Behind the scenes",
    slug: "behind-the-scenes",
    description:
      "Give members a glimpse into your creative process. Tutorial videos or sketches of unfinished pieces, your members will love the chance to be welcomed into your inner circle.",
  },
  {
    id: "rwd_9fj3oaw",
    iconType: "someIcon",
    title: "Early access",
    slug: "early-access",
    description:
      "Give your members early access to your work before the general public.",
  },
  {
    id: "rwd_7d8hfn3",
    iconType: "someIcon",
    title: "Merch",
    slug: "merch",
    description:
      "Offer a merchandise item. We suggest you limit this reward to a high priced level and also require members to enter their shipping information when they sign up.",
  },
  {
    id: "rwd_p94gj21",
    iconType: "someIcon",
    title: "Access to community",
    slug: "access-to-community",
    description:
      "Give your members access to your paid community, this is different from discounts.",
  },
];

export const treatOptions = [
  {
    id: "1",
    name: "Jollof",
    value: "Jollof",
    label: "Jollof",
  },
  {
    id: "2",
    name: "Amala",
    value: "Amala",
    label: "Amala",
  },
  {
    id: "3",
    name: "Dodo",
    value: "Dodo",
    label: "Dodo",
  },
  {
    id: "4",
    name: "Bobotie",
    value: "Bobotie",
    label: "Bobotie",
  },
  {
    id: "5",
    name: "Beer",
    value: "Beer",
    label: "Beer",
  },
  {
    id: "6",
    name: "Chakalaka",
    value: "Chakalaka",
    label: "Chakalaka",
  },
  {
    id: "7",
    name: "Beer",
    value: "Beer",
    label: "Beer",
  },
  {
    id: "8",
    name: "Banku",
    value: "Banku",
    label: "Banku",
  },
  {
    id: "9",
    name: "Ugali",
    value: "Ugali",
    label: "Ugali",
  },
  {
    id: "10",
    name: "Isombe",
    value: "Isombe",
    label: "Isombe",
  },
  {
    id: "11",
    name: "Matoke",
    value: "Matoke",
    label: "Matoke",
  },
  {
    id: "12",
    name: "Fatta",
    value: "Fatta",
    label: "Fatta",
  },
  {
    id: "13",
    name: "Koshari",
    value: "Koshari",
    label: "Koshari",
  },
];

export function capitalizeFirstLetter(string: string) {
  console.log({ string });
  return string.charAt(0).toUpperCase() + string.slice(1);
}
